<template>
  <v-container fluid>
    <breadcrumb route-name="Pagadores" />

    <v-card :loading="loading" class="py-4">
      <v-card-actions class="justify-end">
        <v-btn @click="cancel()" class="mx-2 text--secondary my-auto d-flex ">
          Cancelar
        </v-btn>
        <v-btn class="mx-2 white--text my-auto" color="primary" @click="save()">
          Salvar
        </v-btn>
      </v-card-actions>

      <v-tabs color="orange" flat class="containerInner">
        <v-tab><strong>INFORMAÇÕES GERAIS</strong> </v-tab>
        <v-tab><strong>DEFINIÇÕES DE COBRANÇA</strong></v-tab>
        <v-tab><strong>INFORMAÇÕES ADICIONAIS</strong></v-tab>
        <v-tab><strong>DESCONTO DE PROCEDIMENTO</strong></v-tab>
        <v-tab-item>
          <payer-record-info ref="payerInfo" v-model="payer" />
        </v-tab-item>
        <v-tab-item>
          <payer-record-levy v-model="payer" :roe="roe" />
        </v-tab-item>
        <v-tab-item>
          <payer-record-extra-info v-model="payer" />
        </v-tab-item>
        <v-tab-item>
          <payer-procedure-discount-rules v-model="payer" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import PayerRecordInfo from "./PayerRecordInfo.vue";
import PayerRecordLevy from "./PayerRecordLevy.vue";
import PayerService from "@/app/Services/PayerService";
import PayerRecordExtraInfo from "./components/PayerRecordExtraInfo.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import PayerProcedureDiscountRules from "./components/PayerProcedureDiscountRules.vue";
import { bus } from "@/main";

const payerService = PayerService.build();

export default Vue.extend({
  name: "PayerRecord",
  components: {
    PayerRecordInfo,
    PayerRecordLevy,
    PayerRecordExtraInfo,
    Breadcrumb,
    PayerProcedureDiscountRules
  },
  data() {
    return {
      loading: false,
      payer: {
        id: 0,
        name: "",
        cnpj: "",
        street: "",
        number: "",
        complement: "",
        neighborhood: "",
        city_id: "",
        state_id: "",
        zip_code: "",
        status: 1,
        email: "",
        limit_days_penalty: "",
        exchange_rate: "",
        extra_info: []
      },
      roe: {
        enable: false,
        days: null
      }
    };
  },
  created() {
    this.loadData();
    bus.$on("loadPayerData", this.loadData);
    bus.$on(
      "payer.procedure_discount_enable",
      this.handleChangeProcedureDiscountEnable
    );
    bus.$on("payer.procedure_discount", this.handleChangeProcedureDiscount);
  },
  methods: {
    async loadData() {
      if (this.$route.params.id == "new") {
        return;
      }

      try {
        this.loading = true;

        const response = await payerService.read({ id: this.$route.params.id });

        this.payer = response.data;

        if (this.payer.exchange_rate_roe > 0) {
          this.roe = {
            enable: true,
            exchange_day_roe: this.payer.exchange_day_roe,
            exchange_rate_roe: this.payer.exchange_rate_roe
          };
        }

        if (!this.$refs.payerInfo) {
          return;
        }

        this.$refs.payerInfo.loadCity();
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.push("/payer");
    },
    cancel() {
      this.$router.push("/payer");
    },
    async save() {
      try {
        if (!this.valid()) {
          return;
        }

        this.loading = true;

        const id = this.$route.params.id;
        const payer = this.payer;

        // payer.bank_id = payer.bank.id;
        payer.company_id = payer.company.id;
        payer.roe = this.roe;

        if (id && id != "new") {
          this.payer = await payerService.update(payer);
        } else {
          this.payer = await payerService.create(payer);
        }

        this.loadData();

        this.showToast({
          status: "success",
          message: "Pagador salvo com sucesso"
        });

        this.$router.push("/payer");
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    valid() {
      if (this.payer.name == "") {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Favor digitar o nome!",
          showConfirmButton: false,
          timer: 3500
        });

        return false;
      }

      if (this.payer.street == "") {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Favor digitar o Endereço!",
          showConfirmButton: false,
          timer: 3500
        });

        return false;
      }

      if (this.payer.company_id == "" && !this.payer.company) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Por Favor Selecione um beneficiário",
          showConfirmButton: false,
          timer: 3500
        });

        return false;
      }

      if (this.payer.state_id == "") {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Favor selecionar o Estado!",
          showConfirmButton: false,
          timer: 3500
        });

        return false;
      }

      if (this.payer.city_id == "") {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Favor digitar a Cidade!",
          showConfirmButton: false,
          timer: 3500
        });

        return false;
      }

      if (this.roe.enable) {
        if (!this.roe.exchange_rate_roe) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Favor digitar a Taxa Condição Especial (Câmbio) da ROE!",
            showConfirmButton: false,
            timer: 3500
          });

          return false;
        }

        if (!this.roe.exchange_day_roe) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Favor digitar a Quantidade de Dia(s) da ROE!",
            showConfirmButton: false,
            timer: 3500
          });

          return false;
        }
      }

      return true;
    },
    handleChangeProcedureDiscountEnable(value) {
      this.payer.procedure_discount_enable = value;
    },
    handleChangeProcedureDiscount(value) {
      this.payer.procedure_discount = value;
    }
  }
});
</script>

<style lang="css" scoped>
.v-subheader {
  height: 57px;
  padding: 0;
}
.elevation-1 {
  padding: 60px 37px;
}
.lblUser {
  font-size: 18px;
}
.spaceL {
  margin-left: 10px;
}
.btnHome {
  height: 27px;
}

.custom-btn {
  font-size: 14px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  margin: 10px;
  color: white;
}
.btnCancel {
  background: #a8aab0 !important;
}
.alignButtons {
  width: 360px;
  margin-left: auto;
}
</style>
