<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="mt-5">
        <v-switch
          v-model="payer.procedure_discount_enable"
          color="success"
          hide-details
          inset
          @change="handleChangeEnableDiscount"
        >
          <template v-slot:label>
            <h3>Desconto de Procedimento</h3>
          </template>
        </v-switch>
      </v-col>
      <v-col cols="12" sm="12" lg="4" md="6" xs="12">
        <v-text-field
          label="Desconto de Procedimento (%)"
          v-model="payer.procedure_discount"
          color="text"
          :disabled="!payer.procedure_discount_enable"
          @change="handleChangeValueDiscount"
        />
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">
        <v-btn block elevation="2" @click="dialog = true"
          ><v-icon class="mr-1">mdi-plus</v-icon>Adicionar</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" xs="12">
        <v-data-table
          :headers="headers"
          :items="payer.procedure_discount_rules"
          loading-text="Aguarde...carregando"
          :hide-default-footer="true"
          disable-pagination
          class="elevation-0"
          :header-props="{ sortIcon: null }"
          :loading="loading"
        >
          <template #item.params="{ item }">
            {{
              replacePlaceholders(
                payerProcedureDiscountRulesEnum[item.method].description,
                item.params
              )
            }}
          </template>
          <template #[`item.action`]="{ item }">
            <default-edit-button @click="editDiscountRule(item)" />
            <default-remove-button @click="removeDiscountRule(item)" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <payer-procedure-discount-rules-dialog v-model="dialog" :payer="payer" />
  </v-container>
</template>

<script>
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";
import FormMixin from "@/components/misc/mixins/FormMixin";
import { PayerProcedureDiscountRulesEnum } from "../Enums/PayerProcedureDiscountRulesEnum";
import PayerDiscountRuleService from "@/app/Services/PayerDiscountRuleService";
import PayerProcedureDiscountRulesDialog from "./PayerProcedureDiscountRulesDialog.vue";
import DefaultEditButton from "@/components/layout/DefaultEditButton.vue";
import { bus } from "@/main";

const payerDiscountRuleService = PayerDiscountRuleService.build();

export default {
  components: {
    DefaultRemoveButton,
    PayerProcedureDiscountRulesDialog,
    DefaultEditButton
  },
  props: ["value"],
  name: "PayerProcedureDiscountRules",
  data() {
    return {
      payer: this.value,
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "method"
        },
        {
          text: "Regra",
          align: "start",
          sortable: false,
          value: "params"
        },
        {
          text: "Ações",
          value: "action",
          align: "center",
          sortable: false
        }
      ],
      dialog: false,
      payerProcedureDiscountRulesEnum: PayerProcedureDiscountRulesEnum,
      email: "",
      phone: ""
    };
  },
  mixins: [FormMixin],
  computed: {
    splittedEmails: {
      get() {
        if (!this.payer.email) {
          return [];
        }

        return this.payer.email.split(",");
      },
      set(value) {
        if (!this.payer.email) {
          this.payer.email = `${value}`;

          return;
        }

        this.payer.email += `,${value}`;
      }
    },
    splittedPhones() {
      if (!this.payer.extra_info || !this.payer.extra_info.filter) {
        return [];
      }

      const phones = this.payer.extra_info.filter(info => info.name == "phone");

      return phones;
    }
  },
  methods: {
    replacePlaceholders(text, data) {
      return text.replace(/{{(\w+)}}/g, (_, key) => data[key] || `{{${key}}}`);
    },
    async removeDiscountRule(item) {
      try {
        this.loading = true;

        await payerDiscountRuleService.destroy(item.id);

        this.showToast({
          status: "success",
          message: "Regra removida com sucesso"
        });

        bus.$emit("loadPayerData");
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    editDiscountRule(item) {
      bus.$emit("editDiscountRule", item);
      this.dialog = true;
    },
    handleChangeEnableDiscount() {
      bus.$emit(
        "payer.procedure_discount_enable",
        this.payer.procedure_discount_enable
      );
    },
    handleChangeValueDiscount() {
      bus.$emit("payer.procedure_discount", this.payer.procedure_discount);
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.payer = { ...newVal };
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style></style>
